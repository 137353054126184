import store from '../../store';
// import snackbar from buefy
import { SnackbarProgrammatic } from 'buefy';
export default function (to, from, next, router) {
	let user = store.getters['user/getUserInfo'];
	let token = store.getters['user/getUserToken'];

	if (token && user && user?.type == 'user' && !user?.info?.location?.zipcode) {
		SnackbarProgrammatic.open({
			message:
				'Necessitamos de que atualizes as tua informações e que nos digas qual o teu código postal. Esta informação é importante para que possamos perceber como está a evoluir o nosso projeto no teu concelho. Este dado não te identificará nos nossos relatórios, mas vai nos permitir perceber em que zonas estamos a ter mais impacto. ',
			type: 'is-primary',
			position: 'is-bottom-right',
			indefinite: true,
		});
		next({ name: 'Profile' });
	} else {
		next();
	}
}
