// initial state
const state = {
	mapOpen: false,
};
const initial = JSON.parse(JSON.stringify(state));
// getters
const getters = {
	getmapOpen(state) {
		return state.mapOpen;
	},
};

// mutations
const mutations = {
	resetStore(s) {
		Object.keys(initial).forEach((key) => {
			if (typeof s[key] == 'object') s[key] = JSON.parse(JSON.stringify(initial[key]));
			else s[key] = initial[key];
		});
	},
	changemapOpen(state) {
		state.mapOpen = !state.mapOpen;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
