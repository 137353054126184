import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';
import router from './router';
import i18n from './i18n';
import '@mdi/font/css/materialdesignicons.css';
import './assets/scss/icons.scss';
import './assets/scss/main.scss';
import './assets/scss/spacing.scss';

import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'vue-swatches/dist/vue-swatches.css';

import { _Object } from '@iotechpis/utils';
_Object;

String.prototype.isNumber = (val) => {
	return /^\d+$/.test(val);
};

import VueSocketio from 'vue-socket.io';
Vue.use(
	new VueSocketio({
		debug: process.env.NODE_ENV == 'production' ? false : true,
		connection: process.env.VUE_APP_API_URL,
		vuex: {
			store,
			actionPrefix: 'SOCKET_',
			mutationPrefix: 'SOCKET_',
		},
	})
);

import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueSocialSharing);

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo, {
	container: 'body',
	duration: 500,
	easing: 'ease',
	offset: 0,
	force: true,
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false,
	y: true,
});

import moment from 'moment';
moment.locale('pt');
Vue.prototype.moment = moment;

import * as vClickOutside from 'v-click-outside-x';
Vue.use(vClickOutside);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_ID,
	},
});

import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
if (process.env.NODE_ENV === 'production') {
	if (process.env.VUE_APP_SENTRY)
		Sentry.init({
			Vue,
			dsn: process.env.VUE_APP_SENTRY,
			ignoreErrors: ['NavigationDuplicated'],
			integrations: [
				new BrowserTracing({
					routingInstrumentation: Sentry.vueRouterInstrumentation(router),
					tracingOrigins: ['localhost', 'to-be-green.pt', /^\//],
				}),
			],
			tracesSampleRate: 1.0,
			trackComponents: true,
			logErrors: true,
			attachProps: true,
			attachStacktrace: true,
			beforeSend(event, hint) {
				const error = hint.originalException;
				if (
					error &&
					error.message &&
					(error.message.match(/Navigation cancelled from/i) ||
						error.message.match(/undefined is not an object (evaluating 'navigator.serviceWorker.addEventListener')/i) ||
						error.message.match(/navigator.serviceWorker is undefined/i))
				) {
					return null;
				}
				return event;
			},
		});
	if (process.env.VUE_APP_GA)
		Vue.use(
			VueGtag,
			{
				config: {
					id: process.env.VUE_APP_GA,
				},
			},
			router
		);
}

//Vee-Validate
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import pt from 'vee-validate/dist/locale/pt_PT.json';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

extend('password', {
	params: ['target'],
	validate(value, { target }) {
		return value === target;
	},
	message: 'As passwords têm de ser iguais, por favor repita!',
});

localize('pt', pt);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import Buefy from 'buefy';

Vue.use(Buefy, {
	customIconPacks: {
		tbg: {
			iconPrefix: 'tbg-',
		},
	},
});

import './mixins/index';

import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(Vue2TouchEvents);

Vue.config.productionTip = false;

var VueInstance = new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');

export default VueInstance;
