// initial state
const state = {
	currentApp: {
		_id: 'app',
	},
	currentAppType: 'app',
	refreshStatus: 0,
	query: {
		shared: {},
		myproducts: {},
		recycled: {},
		pending: {},
	},
};
const initial = JSON.parse(JSON.stringify(state));

// getters
const getters = {
	getAppSelected(state) {
		return state.currentApp;
	},
	getAppSelectedType(state) {
		return state.currentAppType;
	},
	getCurrentTab(state) {
		return state.currentTab;
	},
	getQueryShared(state) {
		return state.query.shared;
	},
	getQueryMyProducts(state) {
		return state.query.myproducts;
	},
	getQueryRecycled(state) {
		return state.query.recycled;
	},
	getQueryPending(state) {
		return state.query.pending;
	},
	getRefreshStatus(state) {
		return state.refreshStatus;
	},
};

// mutations
const mutations = {
	resetStore(s) {
		Object.keys(initial).forEach((key) => {
			if (typeof s[key] == 'object') s[key] = JSON.parse(JSON.stringify(initial[key]));
			else s[key] = initial[key];
		});
	},
	changeAppSelected(state, app) {
		state.currentApp = app;
	},
	changeAppSelectedType(state, app) {
		state.currentAppType = app;
	},
	setQueryShared(state, query) {
		state.query.shared = query;
	},
	setQueryMyProducts(state, query) {
		state.query.myproducts = query;
	},
	setQueryRecycled(state, query) {
		state.query.recycled = query;
	},
	setQueryPending(state, query) {
		state.query.pending = query;
	},
	increaseRefreshStatus(state) {
		state.refreshStatus++;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
