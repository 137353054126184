<template>
	<section class="section">
		<div class="container">
			<div>
				<a @click="$router.go(-1)" class="button is-text">
					<b-icon type="is-secondary" icon="chevron-left"></b-icon>
					<span>&nbsp; página anterior</span>
				</a>
			</div>
			<div class="columns is-mobile is-centered">
				<div class="column is-10-mobile is-6-tablet is-4-desktop">
					<ValidationObserver ref="observer" v-slot="{}">
						<h1 class="title is-4 has-text-centered">Contacte-nos</h1>
						<div class="block">
							<label class="label">Motivo do contacto</label>
							<b-radio type="is-dark" name="type" native-value="contact" v-model="contact_form.type" @input="selectContact" selected>Contacto</b-radio>
							<b-radio type="is-dark" name="type" native-value="suggestion" @input="selectContact" v-model="contact_form.type">Sugestão</b-radio>
							<b-radio type="is-dark" name="type" native-value="problem" @input="selectContact" v-model="contact_form.type">Problema</b-radio>
						</div>

						<b-field label="Email" v-if="showContact">
							<ValidationProvider name="Email" rules="required" v-slot="{ errors }">
								<b-input type="text" name="email" v-model.trim="contact_form.email" />
								<span v-if="errors.length > 0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</b-field>

						<b-field label="Nome">
							<ValidationProvider name="Nome" rules="required" v-slot="{ errors }">
								<b-input type="text" name="name" v-model.trim="contact_form.name" />
								<span v-if="errors.length > 0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</b-field>

						<b-field label="Assunto">
							<ValidationProvider name="Assunto" rules="required" v-slot="{ errors }">
								<b-input type="text" name="subject" v-model.trim="contact_form.subject" />
								<span v-if="errors.length > 0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</b-field>

						<b-field label="Mensagem">
							<ValidationProvider name="Mensagem" rules="required" v-slot="{ errors }">
								<b-input type="textarea" name="message" v-model.trim="contact_form.message" />
								<span v-if="errors.length > 0" class="v-error">{{ errors[0] }}!</span>
							</ValidationProvider>
						</b-field>
						<div class="has-text-right">
							<b-button class="is-primary" :loading="buttonLoading" @click="submitContact">Enviar</b-button>
						</div>
					</ValidationObserver>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { registerContact } from '@/api/contact/contact';

	export default {
		name: 'Contact',
		components: {},
		props: {
			user: Object,
		},
		created() {},
		mounted() {},

		data() {
			return {
				contact_form: {
					type: 'contact',
					email: '',
					name: '',
					subject: '',
					message: '',
				},
				showContact: true,
				buttonLoading: false,
			};
		},
		watch: {},
		methods: {
			selectContact() {
				if (this.contact_form.type == 'contact') {
					this.showContact = true;
				} else {
					this.showContact = false;
				}
			},
			submitContact() {
				this.buttonLoading = true;

				registerContact(this.contact_form)
					.then(() => {
						this.$buefy.toast.open({
							message: 'Contacto submetido com sucesso.',
							type: 'is-primary',
							position: 'is-bottom',
						});
						this.contact_form = {
							email: '',
							name: '',
							subject: '',
							message: '',
						};
						this.buttonLoading = false;
						this.$refs.observer.reset();
					})
					.catch((error) => {
						console.log(error);
						this.$buefy.toast.open({
							message: 'Erro na submissão. Por favor tente mais tarde.',
							type: 'is-danger',
							position: 'is-bottom',
						});
						this.buttonLoading = false;
					});
			},
		},
	};
</script>
