import store from '@/store/index';
export const navbar = {
	computed: {
		isBurgerOpen() {
			return store.getters['navbar/getIsBurgerOpen'];
		},
		isFilterOpen() {
			return store.getters['navbar/getIsFilterOpen'];
		},
		isProfileOpen() {
			return store.getters['navbar/getIsProfileOpen'];
		},
		isCartOpen() {
			return store.getters['navbar/getIsCartOpen'];
		},
		isNotificationsOpen() {
			return store.getters['navbar/getIsNotificationsOpen'];
		},
		isSidebarOpen() {
			return store.getters['navbar/getIsSidebarOpen'];
		},
		canSidebarBeOpen() {
			return this.$route.name == 'SharedProducts' || this.$route.name == 'MyProducts';
		},
		isEditProductOpen() {
			return store.getters['navbar/getIsEditProductOpen'];
		},
		isAddProductOpen() {
			return store.getters['navbar/getIsAddProductOpen'];
		},
		isAddBagOpen() {
			return store.getters['navbar/getIsAddBagOpen'];
		},
		isEditBagOpen() {
			return store.getters['navbar/getIsEditBagOpen'];
		},
	},
	methods: {
		toggleBurguerOpen: function () {
			store.commit('navbar/toggleBurgerOpen');
			this.setCartOpen(false);
			this.setProfileOpen(false);
			this.setNotificationsOpen(false);
		},
		toggleFilterOpen: function () {
			store.commit('navbar/toggleFilterOpen');
		},
		toggleProfileOpen: function () {
			store.commit('navbar/toggleProfileOpen');
			this.setNotificationsOpen(false);
			this.setCartOpen(false);
			this.setBurguerOpen(false);
		},
		toggleCartOpen: function () {
			store.commit('navbar/toggleCartOpen');
			this.setNotificationsOpen(false);
			this.setProfileOpen(false);
			this.setBurguerOpen(false);
		},
		toggleSidebarOpen() {
			store.commit('navbar/toggleSidebarOpen');
		},
		toggleNotificationsOpen() {
			store.commit('navbar/toggleNotificationsOpen');
			this.setCartOpen(false);
			this.setProfileOpen(false);
			this.setBurguerOpen(false);
		},
		setBurguerOpen(open) {
			store.commit('navbar/setBurguerOpen', open);
			if (open) {
				this.setCartOpen(false);
				this.setProfileOpen(false);
				this.setNotificationsOpen(false);
			}
		},
		setFilterOpen(open) {
			store.commit('navbar/setFilterOpen', open);
		},
		setCartOpen(open) {
			store.commit('navbar/setCartOpen', open);
			if (open) {
				this.setNotificationsOpen(false);
				this.setProfileOpen(false);
				this.setBurguerOpen(false);
			}
		},
		setProfileOpen(open) {
			store.commit('navbar/setProfileOpen', open);
			if (open) {
				this.setNotificationsOpen(false);
				this.setCartOpen(false);
				this.setBurguerOpen(false);
			}
		},
		setNotificationsOpen(open) {
			store.commit('navbar/setNotificationsOpen', open);
			if (open) {
				this.setCartOpen(false);
				this.setProfileOpen(false);
				this.setBurguerOpen(false);
			}
		},
		setSidebarOpen(open) {
			store.commit('navbar/setSidebarOpen', open);
		},
		setEditProductOpen(open) {
			store.commit('navbar/setEditProductOpen', open);
		},
		setAddProductOpen(open) {
			store.commit('navbar/setAddProductOpen', open);
		},
		setAddBagOpen(open) {
			store.commit('navbar/setAddBagOpen', open);
		},
		setEditBagOpen(open) {
			store.commit('navbar/setEditBagOpen', open);
		},
	},
};
