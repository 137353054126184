import Vue from 'vue';
import VueRouter from 'vue-router';

import Contact from '@/views/Contact';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'ComercialPage',
		component: () => import(/* webpackChunkName: "ComercialPage" */ '@/views/ComercialPage/IFrame.vue'),
		meta: {
			navbarType: null,
            showFooter: false,
		},
	},
	{
		name: 'ComercialPageShare',
		path: '/comercialpage/share',
		component: () => import(/* webpackChunkName: "ComercialPage" */ '@/views/ComercialPage/Share.vue'),
		meta: {
			navbarType: 'NavBar',
		},
	},
	{
		name: 'ComercialRecycle',
		path: '/comercialpage/recycle',
		component: () => import(/* webpackChunkName: "ComercialPage" */ '@/views/ComercialPage/Recycle.vue'),
		meta: {
			navbarType: 'NavBar',
		},
	},
	{
		name: 'ComercialMasks',
		path: '/comercialpage/masks',
		component: () => import(/* webpackChunkName: "ComercialPage" */ '@/views/ComercialPage/Masks.vue'),
		meta: {
			navbarType: 'NavBar',
		},
	},
	{
		path: '/auth',
		name: 'Auth',
		redirect: (to) => {
			return {
				name: 'Login',
			};
		},
		component: () => import(/* webpackChunkName: "Auth" */ '@/views/Auth.vue'),
		meta: {
			redirectToApp: true,
			navbarType: null,
		},
		children: [
			{
				path: 'login',
				name: 'Login',
				component: () => import(/* webpackChunkName: "Auth" */ '@/components/Forms/FormLogin'),
				meta: {
					redirectToApp: true,
					navbarType: null,
				},
			},
			{
				path: 'register',
				name: 'Register',
				component: () => import(/* webpackChunkName: "Auth" */ '@/components/Forms/FormRegister'),
				meta: {
					redirectToApp: true,
					navbarType: null,
				},
			},
			{
				path: 'recovery',
				name: 'Recovery',
				component: () => import(/* webpackChunkName: "Auth" */ '@/components/Forms/FormRecovery'),
				meta: {
					redirectToApp: true,
					navbarType: null,
				},
			},
			{
				path: 'recover-password',
				name: 'RecoverPassword',
				component: () => import(/* webpackChunkName: "Auth" */ '@/components/Forms/FormRecoveryChange'),
				meta: {
					redirectToApp: true,
					navbarType: null,
				},
			},
			{
				path: 'activate/:id',
				name: 'ActivateAccount',
				component: () => import(/* webpackChunkName: "Account" */ '@/views/ActivateAccount.vue'),
				meta: {
					navbarType: null,
				},
			},
			{
				path: '/users/:id/guardian',
				name: 'GuardianAccept',
				component: () => import(/* webpackChunkName: "Account" */ '@/views/GuardianAccept.vue'),
				meta: {
					navbarType: null,
				},
			},
		],
	},
	{
		path: '/app',
		name: 'Main',
		component: () => import(/* webpackChunkName: "Store" */ '@/views/Main'),
		children: [
			{
				path: 'shared',
				alias: '',
				name: 'SharedProducts',
				component: () => import(/* webpackChunkName: "Store" */ '@/components/main/Shared'),
				meta: {
					canAuth: true,
					allowUser: true,
					navbarType: 'AppNavBar',
				},
			},
			{
				path: 'myproducts',
				name: 'MyProducts',
				component: () => import(/* webpackChunkName: "Store" */ '@/components/main/MyProducts'),
				meta: {
					requiresAuth: true,
					navbarType: 'AppNavBar',
				},
			},
			{
				path: 'pending',
				name: 'PendingProducts',
				component: () => import(/* webpackChunkName: "Store" */ '@/components/main/Pending'),
				meta: {
					requiresAuth: true,
					navbarType: 'AppNavBar',
				},
			},
			{
				path: 'favorites',
				name: 'FavoriteProducts',
				component: () => import(/* webpackChunkName: "Store" */ '@/components/main/Favorites'),
				meta: {
					requiresAuth: true,
					allowUser: true,
					navbarType: 'AppNavBar',
				},
			},
			{
				path: 'orders',
				name: 'OrdersProducts',
				component: () => import(/* webpackChunkName: "Store" */ '@/components/main/Orders'),
				meta: {
					requiresAuth: true,
					navbarType: 'AppNavBar',
				},
			},
			{
				path: 'recycle',
				component: () => import(/* webpackChunkName: "Store" */ '@/components/main/Recycled.new.vue'),
				meta: {
					requiresAuth: true,
					navbarType: 'AppNavBar',
				},
				children: [
					{
						path: '',
						name: 'Recycle',
						component: () => import(/* webpackChunkName: "Store" */ '@/components/Recycling/Choose'),
						meta: {
							navbarType: 'AppNavBar',
						},
					},
					{
						path: 'clothes',
						name: 'Recycle-Clothes',
						component: () => import(/* webpackChunkName: "Store" */ '@/components/Recycling/Clothes'),
						meta: {
							navbarType: 'AppNavBar',
						},
					},
					{
						path: 'masks',
						name: 'Recycle-Masks',
						component: () => import(/* webpackChunkName: "Store" */ '@/components/Recycling/Masks'),
						meta: {
							navbarType: 'AppNavBar',
						},
					},
					{
						path: 'store',
						name: 'Recycle-Store',
						component: () => import(/* webpackChunkName: "Store" */ '@/components/Recycling/Store'),
						meta: {
							navbarType: 'AppNavBar',
						},
					},
					{
						path: 'report',
						name: 'Recycle-Report',
						component: () => import(/* webpackChunkName: "Store" */ '@/components/Recycling/Report'),
						meta: {
							navbarType: 'AppNavBar',
						},
					},
					{
						path: 'industry',
						name: 'Recycle-Industry',
						component: () => import(/* webpackChunkName: "Store" */ '@/components/Recycling/Industry'),
						meta: {
							navbarType: 'AppNavBar',
						},
					},
				],
			},
			{
				path: 'bags',
				name: 'BagsList',
				component: () => import(/* webpackChunkName: "Store" */ '@/components/Bags/BagsList.vue'),
				meta: {
					requiresAuth: true,
					navbarType: 'AppNavBar',
				},
			},
		],
	},

	{
		path: '/product/:id',
		name: 'Product',
		component: () => import(/* webpackChunkName: "Store" */ '@/components/Product.vue'),
		meta: {
			canAuth: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/order/:id',
		name: 'Order',
		component: () => import(/* webpackChunkName: "Store" */ '@/views/Order.vue'),
		meta: {
			requiresAuth: true,
			allowUser: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/cart',
		name: 'Cart',
		component: () => import(/* webpackChunkName: "Store" */ '@/views/Cart.vue'),
		meta: {
			requiresAuth: true,
			allowUser: true,
			navbarType: 'AppNavBar',
		},
	},

	{
		path: '/order',
		name: 'Process',
		component: () => import(/* webpackChunkName: "Store" */ '@/views/Process.vue'),
		meta: {
			requiresAuth: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/offline',
		name: 'Offline',
		component: () => import(/* webpackChunkName: "offline" */ '@/views/404.vue'),
		meta: {
			navbarType: null,
		},
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "Store" */ '@/views/Profile.vue'),
		meta: {
			requiresAuth: true,
			isProfile: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/backoffice/institutions',
		name: 'Backoffice-Institutions',
		component: () => import(/* webpackChunkName: "Backoffice" */ '@/views/Backoffice.vue'),
		meta: {
			requiresAuth: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/backoffice/glad',
		name: 'Backoffice-Glad',
		component: () => import(/* webpackChunkName: "Backoffice" */ '@/views/Backoffice.vue'),
		meta: {
			requiresAuth: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/backoffice/places',
		name: 'Backoffice-Places',
		component: () => import(/* webpackChunkName: "Backoffice" */ '@/views/Backoffice.vue'),
		meta: {
			requiresAuth: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/backoffice/partners',
		name: 'Backoffice-Partners',
		component: () => import(/* webpackChunkName: "Backoffice" */ '@/views/Backoffice.vue'),
		meta: {
			requiresAuth: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/backoffice/info',
		name: 'Backoffice-Info',
		component: () => import(/* webpackChunkName: "Backoffice" */ '@/views/Backoffice.vue'),
		meta: {
			requiresAuth: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/backoffice/users',
		name: 'Backoffice-Users',
		component: () => import(/* webpackChunkName: "Backoffice" */ '@/views/Backoffice.vue'),
		meta: {
			requiresAuth: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/backoffice/clients',
		name: 'Backoffice-Clients',
		component: () => import(/* webpackChunkName: "Backoffice" */ '@/views/Backoffice.vue'),
		meta: {
			requiresAuth: true,
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/backoffice/import',
		name: 'Backoffice-Import',
		component: () => import(/* webpackChunkName: "Backoffice" */ '@/views/Backoffice.vue'),
		meta: {
			requiresAuth: true,
			navbarType: 'AppNavBar',
		},
	},

	{
		path: '/institutions/:id',
		name: 'InviteInstitution',
		component: () => import(/* webpackChunkName: "Account" */ '@/views/InviteInstitution.vue'),
		meta: {
			navbarType: null,
		},
	},
	{
		path: '/partners/:id',
		name: 'InvitePartner',
		component: () => import(/* webpackChunkName: "Account" */ '@/views/InvitePartner.vue'),
		meta: {
			navbarType: null,
		},
	},
	{
		path: '/policy/privacy',
		name: 'PrivacyPolicy',
		component: () => import(/* webpackChunkName: "Policy" */ '@/views/Terms/PrivacyPolicy.vue'),
		meta: {
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/policy/rights',
		name: 'MyRights',
		component: () => import(/* webpackChunkName: "Policy" */ '@/views/Terms/MyRights.vue'),
		meta: {
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/policy/terms',
		name: 'TermsConditions',
		component: () => import(/* webpackChunkName: "Policy" */ '@/views/Terms/TermsConditions.vue'),
		meta: {
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/policy/cookies',
		name: 'CookiesPolicy',
		component: () => import(/* webpackChunkName: "Policy" */ '@/views/Terms/CookiesPolicy.vue'),
		meta: {
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/camara-municipal-de-lisboa',
		name: 'ComercialPageLisboa',
		component: () => import(/* webpackChunkName: "ComercialPage" */ '@/views/Lisboa/ComercialPage.vue'),
		meta: {
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
		meta: {
			navbarType: 'AppNavBar',
		},
	},
	{
		path: '/reports',
		name: 'Reports',
		component: () => import(/* webpackChunkName: "Store" */ '@/views/Reports/Pdf.vue'),
		children: [
			{
				path: 'pickup-client',
				name: 'Reports-PickupClient',
				component: () => import(/* webpackChunkName: "ComercialPage" */ '@/views/Reports/PickupClient.vue'),
				meta: {
					navbarType: null,
					showFooter: false,
				},
			},
			{
				path: 'recycling-client',
				name: 'Reports-RecyclingClient',
				component: () => import(/* webpackChunkName: "ComercialPage" */ '@/views/Reports/RecyclingClient.vue'),
				meta: {
					navbarType: null,
					showFooter: false,
				},
			},
			{
				path: 'pickup-municipality',
				name: 'Reports-PickupMunicipality',
				component: () => import(/* webpackChunkName: "ComercialPage" */ '@/views/Reports/PickupMunicipality.vue'),
				meta: {
					navbarType: null,
					showFooter: false,
				},
			},
		],
	},
	...(process.env.NODE_ENV === 'development'
		? [
				{
					path: '/router',
					name: 'Router',
					component: () => import(/* webpackChunkName: "Router" */ '@/views/Router.vue'),
				},
		  ]
		: []),
	{
		path: '*',
		name: '404',
		meta: {
			redirectToHome: true,
			navbarType: 'AppNavBar',
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return {
				selector: to.hash,
			};
		} else {
			return {
				x: 0,
				y: 0,
			};
		}
	},
});

import redirectToApp from './guards/redirectToApp';
import requiresAuth from './guards/requiresAuth';
import canAuth from './guards/canAuth';
import redirectToHome from './guards/redirectToHome';
import allowUser from './guards/allowUser';
import redirectToProfile from './guards/redirectToProfile';

router.beforeEach((to, from, next) => {
	const _requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	const _canAuth = to.matched.some((record) => record.meta.canAuth);
	const _redirectToApp = to.matched.some((record) => record.meta.redirectToApp);
	const _redirectToHome = to.matched.some((record) => record.meta.redirectToHome);
	const _allowUser = to.matched.some((record) => record.meta.allowUser);
	const _isProfile = to.matched.some((record) => record.meta.isProfile);

	if (!_isProfile) redirectToProfile(to, from, next, router);

	if (_requiresAuth) requiresAuth(to, from, next, router);
	else if (_canAuth) canAuth(to, from, next, router);
	else next();

	if (_redirectToHome) redirectToHome(to, from, next, router);

	if (_redirectToApp) redirectToApp(to, from, next, router);

	if (_allowUser) allowUser(to, from, next, router);

	next();
});

export default router;
