// initial state
const state = {
	notifications: [],
};
const initial = JSON.parse(JSON.stringify(state));
// getters
const getters = {
	getNotifications(state) {
		return state.notifications;
	},
};

// mutations
const mutations = {
	resetStore(s) {
		Object.keys(initial).forEach((key) => {
			if (typeof s[key] == 'object') s[key] = JSON.parse(JSON.stringify(initial[key]));
			else s[key] = initial[key];
		});
	},
	saveNotifications(state, _notifications) {
		state.notifications = _notifications;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
