export default [
	{
		img: require('@/assets/social/facebook.svg'),
		link: 'https://www.facebook.com/greenadn',
	},
	{
		img: require('@/assets/social/instagram.svg'),
		link: 'https://www.instagram.com/_tobegreen_/',
	},
	{
		img: require('@/assets/social/twitter.svg'),
		link: 'https://twitter.com/_tobegreen_',
	},
	{
		img: require('@/assets/social/reddit.svg'),
		link: 'https://www.reddit.com/user/_tobegreen_',
	},
	{
		img: require('@/assets/social/linkedin.svg'),
		link: 'https://www.linkedin.com/company/tobegreen/',
	},
	{
		img: require('@/assets/social/youtube.svg'),
		link: 'https://www.youtube.com/channel/UCj_wTF9Cc07DUhZgt8B4C6A',
	},
	{
		img: require('@/assets/social/pinterest.svg'),
		link: 'https://www.pinterest.pt/greenadn/',
	},
];
