<template>
	<div style="width: 100%">
		<div class="modal" :class="{ 'is-active': zoomed }">
			<div class="modal-background" @click="setZoom"></div>
			<div class="modal-content m-image">
				<p class="image">
					<img :src="checkImg(imgsrc)" @error="getDefaultImg" @click="setZoom" :style="imgStyle" />
				</p>
			</div>
			<button class="modal-close is-large" @click="setZoom" aria-label="close"></button>
		</div>
		<div>
			<img class="pending" @click="setZoom()" :src="checkImg(imgsrc)" @error="getDefaultImg" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ImgZoom',
		data() {
			return {
				zoomed: false,
				imgStyle: {
					zoom: null,
					bc: null,
				},
			};
		},
		created() {
			this.imgStyle = {
				height: this.zoom != null ? `${this.zoom * 10}vh` : 'unset',
				'background-color': this.bc != null ? this.bc : 'unset',
			};
		},
		props: {
			imgsrc: {
				type: String,
				default: null,
			},
			zoom: {
				type: Number,
				default: null,
			},
			bc: {
				type: String,
				default: null,
			},
		},
		methods: {
			setZoom() {
				this.zoomed = !this.zoomed;
			},
		},
	};
</script>

<style scoped lang="scss">
	.m-image {
		max-height: 80vh;
		width: unset !important;
	}

	.image > img {
		max-width: 100%;
		max-height: 80vh;
		height: auto;
		width: auto;
		margin: auto;
	}
</style>
