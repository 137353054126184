import store from './../store';
import axios from 'axios';
import VueInstance from '@/main';

import { user } from '@/mixins/modules/user';

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status == 401 && VueInstance.$route.name != 'Login' && VueInstance.getUserToken) {
			user.methods.sessionExpired();
		}
		return Promise.reject(error);
	}
);

const getHeaders = () => {
	let token = store.getters['user/getUserToken'];
	if (token) {
		return {
			Authorization: token,
		};
	}
};

export function post(uri, data) {
	return axios.post(endpoint(uri), data, {
		headers: getHeaders(),
	});
}

export function put(uri, data) {
	return axios.put(endpoint(uri), data, {
		headers: getHeaders(),
	});
}

export function remove(uri, data) {
	return axios.delete(endpoint(uri), {
		data,
		headers: getHeaders(),
	});
}

export function get(uri) {
	return axios.get(endpoint(uri), {
		headers: getHeaders(),
	});
}

export function endpoint(uri) {
	return process.env.VUE_APP_API_URL + uri;
}
