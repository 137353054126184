import { user } from '@/mixins/modules/user';

export default function (to, from, next) {
	if (to.name == from.name) return next();
	let token = user.computed.getUserToken();
	if (token) {
		next();
	} else {
		user.methods.sessionExpired();
	}
}
