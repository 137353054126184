let defaultImg = require('@/assets/img/default-img.png');

import browser from 'browser-detect';
import store from '@/store/index';
export const app = {
	computed: {
		getAppSelected() {
			return store.getters['app/getAppSelected'];
		},
		getAppSelectedType() {
			return store.getters['app/getAppSelectedType'];
		},
		getQueryShared() {
			return store.getters['app/getQueryShared'];
		},
		getQueryMyProducts() {
			return store.getters['app/getQueryMyProducts'];
		},
		getQueryRecycled() {
			return store.getters['app/getQueryRecycled'];
		},
		getQueryPending() {
			return store.getters['app/getQueryPending'];
		},
		getRefreshStatus() {
			return store.getters['app/getRefreshStatus'];
		},
	},
	methods: {
		changeAppSelected(app) {
			store.commit('app/changeAppSelected', app);
		},
		changeAppSelectedType(app) {
			store.commit('app/changeAppSelectedType', app);
		},
		scrollToTop() {
			if (browser().name == 'safari')
				setTimeout(function () {
					scrollTo(0, 0);
				}, 100);
			else document.documentElement.scrollTop = 0;
		},
		scrollTo(view, hash) {
			if (this.$route.name == view) document.getElementById(hash).scrollIntoView(true);
			else
				this.$router.push({
					name: view,
					hash: `#${hash}`,
				});
		},
		setQueryShared(query) {
			store.commit('app/setQueryShared', Object.clone(query));
		},
		setQueryMyProducts(query) {
			store.commit('app/setQueryMyProducts', Object.clone(query));
		},
		setQueryRecycled(query) {
			store.commit('app/setQueryRecycled', Object.clone(query));
		},
		setQueryPending(query) {
			store.commit('app/setQueryPending', Object.clone(query));
		},
		checkImg(img) {
			if (!img) return defaultImg;
			return img;
		},
		getDefaultImg(event, single = true) {
			if (single) return (event.target.src = defaultImg);
			else return event.target.remove();
		},
		increaseRefreshStatus() {
			store.commit('app/increaseRefreshStatus');
		},
	},
};
