import Vue from 'vue';

// initial state
const state = {
	glads: [],
	institutions: [],
	places: [],
	partners: [],
	info: [],
	users: {
		count: 0,
		pages: [],
	},
	currentTab: 'Institutions',
};
const initial = JSON.parse(JSON.stringify(state));
// getters
const getters = {
	getGlads(state) {
		return state.glads;
	},
	getInstitutions(state) {
		return state.institutions;
	},
	getPlaces(state) {
		return state.places;
	},
	getPartners(state) {
		return state.partners;
	},
	getInfo(state) {
		return state.info;
	},

	getUsersByPage: (state) => (_page) => {
		return state.users.pages.find((page) => page.page == _page);
	},
	getUsersCount(state) {
		return state.users.count;
	},

	getCurrentTab(state) {
		return state.currentTab;
	},
};

// mutations
const mutations = {
	resetStore(s) {
		Object.keys(initial).forEach((key) => {
			if (typeof s[key] == 'object') s[key] = JSON.parse(JSON.stringify(initial[key]));
			else s[key] = initial[key];
		});
	},
	resetBackofficeStoreByKey(s, key) {
		s[key] = JSON.parse(JSON.stringify(initial[key]));
	},

	addUsersPage(state, __page) {
		let _page = __page;
		if (!Array.isArray(__page)) _page = [__page];

		for (let page of _page) {
			let index = (function () {
				for (let i in state.users.pages) if (state.users.pages[i].page == page.page) return i;
				return -1;
			})();
			if (index != -1) {
				Vue.set(state.users.pages, index, page);
			} else {
				state.users.pages.push(page);
			}
		}
	},
	setUsersCount(state, count) {
		state.users.count = count;
	},

	setGlads(state, obj) {
		Vue.set(state, 'glads', obj);
	},
	setInstitutions(state, obj) {
		Vue.set(state, 'institutions', obj);
	},
	setPlaces(state, obj) {
		Vue.set(state, 'places', obj);
	},
	setPartners(state, obj) {
		Vue.set(state, 'partners', obj);
	},
	setInfo(state, obj) {
		Vue.set(state, 'info', obj);
	},
	setCurrentTab(state, tab) {
		state.currentTab = tab;
	},
	removeGlad(state, _id) {
		for (let i in state.glads) {
			if (state.glads[i]._id == _id) Vue.delete(state.glads, i);
		}
	},
	removeInstitution(state, _id) {
		for (let i in state.institutions) {
			if (state.institutions[i]._id == _id) Vue.delete(state.institutions, i);
		}
	},
	removePlace(state, _id) {
		for (let i in state.places) {
			if (state.places[i]._id == _id) Vue.delete(state.places, i);
		}
	},
	removePartner(state, _id) {
		for (let i in state.partners) {
			if (state.partners[i]._id == _id) Vue.delete(state.partners, i);
		}
	},
	updateGlad(state, obj) {
		for (let i in state.glads) {
			if (state.glads[i]._id == obj._id) Vue.set(state.glads, i, obj);
		}
	},
	updatePlace(state, obj) {
		for (let i in state.places) {
			if (state.places[i]._id == obj._id) Vue.set(state.places, i, obj);
		}
	},
	updatePartner(state, obj) {
		for (let i in state.partners) {
			if (state.partners[i]._id == obj._id) Vue.set(state.partners, i, obj);
		}
	},
	updateInstitution(state, obj) {
		for (let i in state.institutions) {
			if (state.institutions[i]._id == obj._id) Vue.set(state.institutions, i, obj);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,

	mutations,
};
