<template>
	<nav class="comercial navbar is-fixed-top top-nav sa-padding-top" role="navigation" aria-label="main navigation">
		<div class="navbar-brand">
			<a @click="goToComercial" class="navbar-item">
				<img class="img appnavbar" src="@/assets/logo/logo.svg" />
			</a>

			<a
				role="button"
				class="navbar-burger burger"
				aria-label="menu"
				aria-expanded="false"
				data-target="navbarBasicExample"
				:class="{ 'is-active': isBurgerOpen }"
				@click="toggleBurguerOpen()"
			>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>

		<div class="navbar-end comercial">
			<div id="navbarBasicExample" :class="{ 'is-active': isBurgerOpen }" class="navbar-menu">
				<div class="navbar-start">
					<a @click.prevent="scrollTo('ComercialPage', 'about')" class="navbar-item">Sobre</a>
					<a @click.prevent="scrollTo('ComercialPage', 'where')" class="navbar-item">Onde estamos</a>
					<a @click.prevent="scrollTo('ComercialPage', 'partners')" class="navbar-item">Parceiros</a>
					<a @click.prevent="scrollTo('ComercialPage', 'blog')" class="navbar-item">Blog</a>
					<a @click="goToApp" class="navbar-item">Loja</a>
					<a @click="goToLogin" class="navbar-item">Login</a>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	export default {
		name: 'Navbar',
		props: {},
		data() {
			return {
				open: true,
			};
		},
		methods: {
			goToLogin() {
				if (this.isBurgerOpen) this.toggleBurguerOpen();

				this.$router.push({ name: 'Login' });
			},
			goToRegister() {
				this.$router.push({ name: 'Register' });
			},
			goToApp() {
				this.$router.push({ name: 'SharedProducts' });
			},
			goToComercial() {
				this.$router.push({ name: 'ComercialPage' });
			},
			changeButtonVisibility: function (tag) {
				this.toggleBurguerOpen();
				document.getElementById(tag).scrollIntoView(true);
			},
		},
		computed: {},
	};
</script>

<style scoped lang="scss">
	.profile-picture {
		padding-right: 0px !important;
	}

	nav {
		// height: 60px;
	}
	.navbar-burger {
		margin-top: auto;
		margin-bottom: auto;
	}

	.navbar-brand > a {
		text-align: left;
	}

	img {
		max-height: 100% !important;
	}

	a {
		font-size: 20px;
		font-weight: bold;
		margin-right: 20px;
		text-align: center;
	}
</style>
