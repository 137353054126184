import store from '@/store/index';
export const device = {
	computed: {
		isMobile() {
			return store.getters['device/isMobile'];
		},
		isMobileDevice() {
			let check = false;

			if (process.env.VUE_APP_CAPACITOR_PLATFORM && process.env.VUE_APP_CAPACITOR_PLATFORM != 'browser') check = true;

			return check;
		},
	},
	methods: {
		updateWidth(width) {
			store.commit('device/updateWidth', width);
		},
	},
};
