import { post, get } from './../../utils/http';

export function registerInstall() {
	let uri = '/info';
	return post(uri);
}

export function getInfo() {
	let uri = '/info';
	return get(uri);
}

export function getPartners() {
	let uri = '/info/partners';
	return get(uri);
}
