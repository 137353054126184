<template>
	<div>
		<!-- NAVBAR MOBILE -->
		<nav v-click-outside="closeBurger" class="navbar sa-padding-top is-fixed-top is-hidden-desktop top-nav">
			<div class="navbar-brand">
				<a
					role="button"
					class="navbar-burger"
					aria-label="menu"
					aria-expanded="false"
					data-target="navbarmenu"
					:class="{ 'is-active': isBurgerOpen }"
					@click="toggleBurguerOpen()"
					v-show="getUserInfo.type != 'user'"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>

				<a v-show="canSidebarBeOpen" class="navbar-item navbar-icon" @click="setSidebarOpen(!isSidebarOpen)">
					<b-icon icon="filter"></b-icon>
				</a>

				<a class="navbar-item" @click="goToCommercial()">
					<img src="./../../assets/logo/logo_app.svg" alt="ToBeGreen" />
				</a>

				<a v-if="getUserToken" class="navbar-item navbar-icon" @click.stop="toggleNotificationsOpen()">
					<b-icon icon="bell"></b-icon>
					<span class="badge">{{ unreadNotificationsNumber }}</span>
				</a>

				<a v-if="getUserToken && user.type == 'user' && !fromSpecialPlace" class="navbar-item navbar-icon" @click.stop="toggleCartOpen()">
					<b-icon icon="cart"></b-icon>
					<span class="badge">
						{{ getCart(getAppSelected._id).products.length }}
					</span>
				</a>

				<a v-if="getUserToken" class="navbar-item navbar-icon" @click.stop="toggleProfileOpen()">
					<b-icon icon="account"></b-icon>
				</a>
			</div>

			<div id="navbarmenu" :class="{ 'is-active': isBurgerOpen }" class="navbar-menu">
				<b-select v-if="getUserToken" @input="changeApp" class="navbar-item" expanded v-model="currentApp">
					<option value="app" default>Aplicação</option>
					<optgroup v-if="getUserInfo.institutions.length > 0" label="Instituições">
						<option v-for="i in getUserInfo.institutions" :value="i._id" :key="i._id">
							{{ i.name }}
						</option>
					</optgroup>

					<optgroup v-if="getUserInfo.partners.length > 0" label="Munícipios Parceiros">
						<option v-for="i in getUserInfo.partners" :value="i._id" :key="i._id">
							{{ i.name }}
						</option>
					</optgroup>

					<optgroup v-if="getUserInfo.places.length > 0 && getUserInfo.type != 'user'" label="Locais">
						<option v-for="i in getUserInfo.places" :value="i._id" :key="i._id">
							{{ i.name }}
						</option>
					</optgroup>
				</b-select>

				<div v-if="!getUserToken" class="navbar-item">
					<a @click="goToRegister()" class="navbar-item has-text-centered">Registar</a>
					<a @click="goToLogin()" class="button is-primary navbar-item has-text-centered" style="color: white !important">Login</a>
				</div>

				<a v-if="getUserToken && getUserInfo.type == 'admin'" @click.stop="openBackoffice" class="navbar-item has-text-centered">Backoffice</a>
			</div>
		</nav>
		<!-- NAVBAR DESKTOP -->
		<nav class="navbar is-fixed-top sa-padding-top is-hidden-touch top-nav">
			<div class="navbar-menu">
				<div class="navbar-start">
					<b-select v-show="getUserInfo.type != 'user'" expanded v-if="getUserToken" @input="changeApp" class="navbar-item" v-model="currentApp">
						<option value="app" default>Aplicação</option>
						<optgroup v-if="user.institutions.length > 0" label="Instituições">
							<option v-for="i in user.institutions" :value="i._id" :key="i._id">
								{{ i.name }}
							</option>
						</optgroup>

						<optgroup v-if="user.partners.length > 0" label="Munícipios Parceiros">
							<option v-for="i in user.partners" :value="i._id" :key="i._id">
								{{ i.name }}
							</option>
						</optgroup>

						<optgroup v-if="user.places.length > 0 && user.type != 'user'" label="Locais">
							<option v-for="i in user.places" :value="i._id" :key="i._id">
								{{ i.name }}
							</option>
						</optgroup>
					</b-select>
				</div>
				<div class="navbar-brand" :class="!getUserToken ? 'padding-r' : ''">
					<a class="navbar-item" @click="goToCommercial()">
						<img class="img appnavbar" src="./../../assets/logo/logo_app.svg" alt="ToBeGreen" />
					</a>
				</div>
				<div class="navbar-end">
					<a v-if="getUserToken && getUserInfo.type == 'admin'" @click.stop="openBackoffice" class="navbar-item">Backoffice</a>

					<a v-if="getUserToken" class="navbar-item" @click.stop="toggleNotificationsOpen()">
						<b-icon icon="bell"></b-icon>
						<span class="badge">{{ unreadNotificationsNumber }}</span>
					</a>

					<a v-if="getUserToken && user.type != 'admin' && user.type != 'glad' && !fromSpecialPlace" class="navbar-item" @click.stop="toggleCartOpen()">
						<b-icon icon="cart"></b-icon>
						<span class="badge">{{ getCart(getAppSelected._id).products.length }}</span>
					</a>

					<a v-if="getUserToken" class="navbar-item" @click.stop="toggleProfileOpen()">
						<b-icon icon="account"></b-icon>
					</a>
					<div class="navbar-item">
						<a v-if="!getUserToken" @click="goToRegister()" style="margin-right: 20px">Registar</a>
						<a v-if="!getUserToken" @click="goToLogin()" class="button is-primary">Login</a>
					</div>
				</div>
			</div>
		</nav>
		<b-loading :active.sync="isLoading"></b-loading>
		<CartModal @gotocart="goToCart" v-click-outside="outsideCart" v-if="isCartOpen" :cart="getCart(getAppSelected._id)" />

		<ProfileModal @gotoprofile="goToProfile" @logout="logOut" v-click-outside="outsideProfile" v-if="isProfileOpen" />

		<NotificationsModal v-click-outside="outsideNotifications" v-if="isNotificationsOpen" />
	</div>
</template>

<script>
	//Mixins
	import { cart } from '../../mixins/modules/cart';
	import { order } from '../../mixins/modules/order';

	import { notifications } from '../../mixins/modules/notifications.js';

	import CartModal from './CartModal';
	import ProfileModal from './ProfileModal';
	import NotificationsModal from './NotificationsModal';

	export default {
		name: 'AppNavBar',
		components: {
			CartModal,
			ProfileModal,
			NotificationsModal,
		},
		props: {},
		mixins: [cart, order, notifications],
		created() {
			this.user = this.getUserInfo;
			this.currentApp = this.getAppSelected._id;
			this.cart = this.getCart(this.getAppSelected._id);
		},

		watch: {
			getCart: function () {
				this.cart = this.getCart(this.getAppSelected._id);
			},
			getAppSelected: function () {
				this.cart = this.getCart(this.getAppSelected._id);
			},
		},
		mounted() {},
		data() {
			return {
				isLoading: false,
				currentApp: 'app',
				user: {},
				cart: [],
			};
		},
		methods: {
			changeApp() {
				let current_app = this.getAppSelected;
				if (current_app._id == this.currentApp) return;

				let app = {
					_id: 'app',
				};

				for (let i of this.user.institutions)
					if (this.currentApp == i._id) {
						app = i;
						this.changeAppSelectedType('institution');
					}

				for (let i of this.user.places)
					if (this.currentApp == i._id) {
						app = i;
						this.changeAppSelectedType('place');
					}

				for (let i of this.user.partners)
					if (this.currentApp == i._id) {
						app = i;
						this.changeAppSelectedType('partner');
					}

				if (app._id == 'app') this.changeAppSelectedType('app');
				this.changeAppSelected(app);
				if (this.$route.name != 'SharedProducts') this.$router.push({ name: 'SharedProducts' });
				this.increaseRefreshStatus();
			},
			closeBurger() {
				this.setBurguerOpen(false);
			},
			outsideCart() {
				this.setCartOpen(false);
			},
			outsideProfile() {
				this.setProfileOpen(false);
			},
			outsideNotifications() {
				this.setNotificationsOpen(false);
			},
			goToCommercial() {
				this.$router.push({ name: 'ComercialPage' });
			},
			goToHome() {
				this.$router.push({ name: 'SharedProducts' });
			},
			goToCart() {
				this.toggleCartOpen();
				this.$router.push({ name: 'Cart' });
			},
			goToProfile() {
				this.toggleProfileOpen();
				this.$router.push({ name: 'Profile' });
			},
			openBackoffice() {
				this.$router.push({ name: 'Backoffice-Institutions' });
			},
			logOut() {
				this.resetStores();
				this.$router.push({ name: 'ComercialPage' });
			},
			cleanFilter() {
				this.$router.push({ name: 'SharedProducts' });
			},
			goToLogin() {
				// if (this.isBurgerOpen) this.toggleBurguerOpen();

				this.$router.push({ name: 'Login' });
			},
			goToRegister() {
				this.$router.push({ name: 'Register' });
			},
		},
		computed: {},
	};
</script>

<style scoped lang="scss">
	.navbar-burger {
		margin-left: unset;
	}

	.navbar-brand {
		a:nth-of-type(3) {
			margin: auto;
			img {
				margin: auto;
			}
		}
	}

	.is-hidden-desktop {
		.navbar-brand {
			position: relative;
			.navbar-item:nth-of-type(3) {
				position: absolute;
				height: 100%;
				width: 72px;
				left: 50%;
				transform: translate(-50%);
			}
			.navbar-item:nth-of-type(4) {
				margin-left: auto;
			}
			.navbar-icon {
				min-width: 3.25rem;
				.icon {
					margin: auto;
				}
			}
		}
	}

	.is-hidden-touch {
		.navbar-menu {
			position: relative;
			.navbar-brand {
				position: absolute;
				left: 50%;
				height: 100%;
				transform: translate(-50%);
			}
			.navbar-start {
				.navbar-item:nth-of-type(1) {
					min-width: 300px;
				}
			}
		}
	}

	a.navbar-item:hover {
		color: unset;
		background-color: unset;
	}
</style>
