import store from '../../store';

export default function (to, from, next) {
	if (to.name == from.name) return next();
	let token = store.getters['user/getUserToken'];
	if (token) {
		next();
		// tokenAuth()
		//     .then(response => {
		//         store.commit('user/addUser', response.data.body)
		//         store.commit('user/addUserID', response.data.body._id)
		//         // next()
		//     })
		//     .catch(error => {
		//         console.log(error)
		//         Toast.open({
		//             message: "Sessão expirada, repita o processo!",
		//             type: "is-danger",
		//             position: "is-bottom"
		//         });
		//         store.commit('user/resetStore')
		//         store.commit('cart/resetStore')
		//         store.commit('map/resetStore')
		//         store.commit('navbar/resetStore')
		//         store.commit('order/resetStore')
		//         store.commit('app/resetStore')
		//         store.commit('notifications/resetStore')
		//         router.push({ name: 'Login' });
		//     })
	} else {
		next();
	}
}
